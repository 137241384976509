import "./card-style.css";

const BirthdayCard = () => (
    <div className="container">
        <h3>Click or hover over the card!</h3>
        <div className="birthdayCard">
            <div className="cardFront">
                <h3 className="happy">HAPPY BIRTHDAY OSHINI</h3>
                <div className="balloons">
                    <div className="balloonOne" />
                    <div className="balloonTwo" />
                    <div className="balloonThree" />
                    <div className="balloonFour" />
                </div>
            </div>
            <div className="cardInside">
                <h3 className="back">HAPPY 🎂 BIRTHDAY</h3>
                <p>Dear Oshini,</p>
                <p>
                    Happy birthday! We know that you've been enjoying learning
                    to code, and we wanted to make sure the spark stays alive.
                    So, this domain is for you. We hope you like it (sadly,
                    oshini.com was taken). Every programmer should have their
                    own website, and let this be the start of yours.
                </p>
                <p>
                    We're so proud of you, and just remember we're both always
                    here for you!
                </p>
                <p>Lots of love,</p>
                <p>Tharaka & Hrithik</p>
            </div>
        </div>
    </div>
);

export default BirthdayCard;
